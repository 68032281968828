import * as React from 'react'

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

import { CreateMedia } from './__generated__/CreateMedia'
import { gql } from 'apollo-boost'
import { useDropzone } from 'react-dropzone'
import { useMutation } from '@apollo/react-hooks'

type MediaDetailModalProps = IMediaDetailModalProps

interface IMediaDetailModalProps {
  mediaId?: string | null
  open: boolean
  onClose: () => void
  onSave?: (mediaId: string, previewUrl: string) => void
  aspectRatio?: number
}

const CREATE_MEDIA_MUTATION = gql`
  mutation CreateMedia {
    createMedia {
      media {
        id
        cdnUrl
      }
      uploadUrl
    }
  }
`

const useCreateMediaMutation = () => useMutation<CreateMedia>(CREATE_MEDIA_MUTATION)

export const MediaDetailModal: React.FC<MediaDetailModalProps> = props => {
  const title = props.mediaId ? `Media: ${props.mediaId}` : 'New Media'
  const [createMedia] = useCreateMediaMutation()
  const [saving, setSaving] = React.useState(false)
  const [, setUploadError] = React.useState(false)
  const [, setUploaded] = React.useState(false)

  const [image, setImage] = React.useState<URL | null>(null)

  const save = async () => {
    setSaving(true)
    const createdMedia = await createMedia({})
    if (!createdMedia.data) throw Error()
    if (!image) throw Error()

    let blob = await fetch(String(image)).then(r => r.blob())

    const xhr = new XMLHttpRequest()

    xhr.open('PUT', createdMedia.data.createMedia.uploadUrl, true)
    xhr.onload = () => {
      const status = xhr.status
      if (status === 200 && createdMedia?.data) {
        setUploaded(true)
        setSaving(false)
        props.onSave?.(createdMedia.data?.createMedia.media.id, createdMedia.data?.createMedia.media.cdnUrl)
      } else {
        setUploadError(true)
        setSaving(false)
      }
    }

    xhr.onerror = () => {
      setUploadError(true)
      setUploaded(false)
      setSaving(false)
    }
    xhr.setRequestHeader('Content-Type', blob.type)
    xhr.send(blob)
  }

  return (
    <Dialog maxWidth='lg' open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <CroppableImageDropzone aspectRatio={props.aspectRatio ?? 1} onChange={setImage} />
      </DialogContent>
      <DialogActions>
        {save && (
          <Button disabled={!image} onClick={save} variant='contained'>
            {saving ? <CircularProgress /> : 'Save'}
          </Button>
        )}
        <Button onClick={() => props.onClose()} variant='outlined'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface ICroppableImageDropzoneProps {
  aspectRatio?: number
  onChange: (blob: URL) => void
}

type CroppableImageDropzoneProps = ICroppableImageDropzoneProps

const CroppableImageDropzone: React.FC<CroppableImageDropzoneProps> = props => {
  const [files, setFiles] = React.useState<any[]>([])

  const onDrop = React.useCallback(acceptedFiles => {
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    )
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: 'image/jpeg, image/png, image/webp',
    onDrop,
    noClick: true
  })

  const preview = files[0]?.preview

  React.useEffect(() => {
    props.onChange(files[0]?.preview)
  }, [files, props])

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {preview ? (
        <Box style={{ width: 400 }}>
          <img src={preview} alt='preview' style={{ maxWidth: '100%' }} />
        </Box>
      ) : (
        <Box style={{ width: 400, border: '2px dotted black' }}>
          {isDragActive ? <p>Drop here</p> : <p>Drag and drop an image file here</p>}
        </Box>
      )}
    </div>
  )
}

export const getCroppedImg = (image: any, crop: any, fileName: any) => {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = Math.ceil(crop.width * scaleX)
  canvas.height = Math.ceil(crop.height * scaleY)
  const ctx = canvas.getContext('2d')
  ctx?.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  )
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        resolve(blob)
      },
      'image/jpeg',
      1
    )
  })
}
