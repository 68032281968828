export const FULL_ACCESS = ['tyson@vestaboard.com', 'mark@vestaboard.com']
export const SUBSCRIPTION_EDITORS = [
  ...FULL_ACCESS,
  'aaron@vestaboard.com',
  'chakhala@vestaboard.com',
  'tito@vestaboard.com'
]
export const REPORTS_API = 'https://reports.vestaboard.com/api'
export const drawerWidth = 240
export const SITE_TITLE = 'Vestaboard Superadmin'
export const INSTALLABLES_MONOREPO_API = 'https://f31e349e.installables.vbrd.net'
export const PLATFORM_API = process.env.REACT_APP_API_BASE_URL
