import React, { useState } from 'react'

import { CircularProgress } from '@material-ui/core'
import { CsvImportButton } from '../../ui/CsvImportButton'
import { sleep } from '../../utils/sleep'

interface IResponse {
  deviceId: string
  pairingCode: string
}

const kickoffHawkbitUpgrade = async (device: IResponse) => {
  return await fetch(`https://platform.vestaboard.com/v3.0/force-upgrade/${device.deviceId}`, {
    method: 'POST',
    headers: {
      'X-Vestaboard-Pairing-Code': device.pairingCode
    }
  })
}

export const HawkbitUploadButton = (props: {
  devices: Array<IResponse>
  setDevices: (devices: Array<IResponse>) => void
  setRequestedUpgrade: (requestedUpgrade: Array<IResponse>) => void
  setErrors: (errors: Array<string>) => void
}) => {
  return (
    <CsvImportButton
      mainButtonText={'Upload Hawkbit CSV'}
      buttonText={'Start Upgrade Process'}
      mappings={['deviceId', 'pairingCode']}
      handleImport={async data => {
        // @ts-ignore
        props.setDevices(data as Array<IResponse>)
        await data.forEach(async device => {
          try {
            // spread out upgrade requests
            await sleep(1000)
            // @ts-ignore
            await kickoffHawkbitUpgrade(device)
            // @ts-ignore
            props.setRequestedUpgrade(prev => [...prev, device])
            return
          } catch (e) {
            console.error(e)
            // @ts-ignore
            props.setErrors(prev => [...prev, `Error sending ${device.deviceId}`])
          }
        })
      }}
    />
  )
}

export const HawkbitUploader: React.FC = props => {
  const [devices, setDevices] = useState<
    Array<{
      deviceId: string
      pairingCode: string
    }>
  >([])
  console.log(devices)
  const [requesteUpgrade, setRequestedUpgrade] = useState<
    Array<{
      deviceId: string
      pairingCode: string
    }>
  >([])
  const [errors, setErrors] = useState<Array<string>>([])

  return (
    <>
      <HawkbitUploadButton
        devices={devices}
        setDevices={setDevices}
        setRequestedUpgrade={setRequestedUpgrade}
        setErrors={setErrors}
      />
      <div>
        <h2>Devices set to Upgrade via Hawkbit</h2>
        {devices.length > 0 &&
          (requesteUpgrade.length < devices.length ? (
            <>
              <h3>Requesting Upgrade...</h3>
              <CircularProgress />
            </>
          ) : (
            <h3>
              Requested {requesteUpgrade.length} {requesteUpgrade.length === 1 ? 'upgrade' : 'upgrades'}
            </h3>
          ))}
        {requesteUpgrade.length > 0 && (
          <h3>
            {/* Version "71" in hawkbit mysql DB aka 3.1.5, for now this is hardcoded on platform backend */}
            Devices that will be upgraded via{' '}
            <a href={`https://hawkbit.vestaboard.com`} target='_blank' rel='noopener noreferrer'>
              Hawkbit
            </a>{' '}
            to v3.1.5:
          </h3>
        )}
        {errors.length > 0 && (
          <ul>
            {errors.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        )}
        <ul>
          {requesteUpgrade.map(device => (
            <li key={device.deviceId}>
              {device.deviceId} - {device.pairingCode}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
