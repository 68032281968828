import { ProductApplication, ProductApplicationVariables } from './__generated__/ProductApplication'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const PRODUCT_APPLICATION = gql`
  query ProductApplication($id: String!) {
    viewer {
      id
      account {
        id
        emailAddress
      }
    }
    productApplication(id: $id) {
      id
      created
      started
      ends
      shopifyTransactionId
      optOutOfAutoRenewal
      optOutReason
      optOutDate
      forcedThreeYearContract
      activationCode {
        id
        code
        isInvalidated
        isUsed
      }
      pricing {
        id
        title
        tagline
        autoRenew
        banner
        chargeFrequency
        created
        title
        priceAfterFirstInCents
        priceInCents
        quantity
        shopifySellingPlanId
        shopifyVariantId
        product {
          id
          title
        }
      }
      purchaseLineItem {
        id
        created
        purchase {
          id
          emailAddress
          created
          purchaseSession {
            id
            shopifyOrderId
            shopifyUserId
            shopifyOrderName
            paymentMethod {
              id
              company
              number
            }
          }
          account {
            id
            person {
              id
              firstName
              lastName
            }
          }
        }
      }
      billingDates(limit: 100)
      board {
        id
        title
      }
    }
  }
`

export const useGetProductApplication = (id: string) => {
  return useQuery<ProductApplication, ProductApplicationVariables>(PRODUCT_APPLICATION, {
    variables: {
      id
    }
  })
}
