import { Product, ProductVariables } from './__generated__/Product'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const QUERY = gql`
  query Product(
    $id: String!
    $terms: String
    $page: Int
    $perPage: Int
    $sortBy: ProductApplicationSortBy
    $sortOrder: SortOrder
  ) {
    product(id: $id) {
      id
      listProductApplications(terms: $terms, perPage: $perPage, page: $page, sortBy: $sortBy, sortOrder: $sortOrder) {
        productApplications {
          id
          created
          started
          ends
          forcedThreeYearContract
          shopifyTransactionId
          pricing {
            id
            title
          }
          optOutOfAutoRenewal
          optOutReason
          optOutDate
          activationCode {
            id
            code
            isInvalidated
            isUsed
          }
          board {
            id
            title
          }
          purchaseLineItem {
            id
            purchase {
              id
              emailAddress
              purchaseSession {
                id
                shopifyOrderId
                shopifyUserId
                shopifyOrderName
              }
              account {
                id
                person {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
        pagination {
          page
          total
          perPage
        }
      }
    }
  }
`

export const useListProductApplications = (variables: ProductVariables) => {
  return useQuery<Product, ProductVariables>(QUERY, { variables })
}
